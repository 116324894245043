<template>
<section class="px-3">
  <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
  <div class="pt-6">
    <div v-for="(list, index) in inboxList" :key="index">
    <b-card class="text-left shadow round-16 mb-2">
      <b-media class="py-1" style="">
        <template #aside>
          <b-img :src="require('@/assets/icon-inbox.png')" alt="placeholder"></b-img>
        </template>
        <div class="title">
          <h5 class="mt-0 font-weight-bold mb-0" style="font-size: 18px;">{{list.name}}</h5>
          <span style="font-size: 10px">{{addHours(list.created)}}</span>
        </div>
        <p class="fs-9 inbox-content mb-1" style="text-align: start; font-size: 14px !important;" v-html="wordDesc(list.description)">
        </p>
        <h6 class="mt-0 fs-9 font-weight-bold" @click="handleClick(list.id)" >Read More</h6>
      </b-media>
    </b-card>
    </div>
  </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex' 
import Header from '@/components/Header.vue'
export default {
  name: 'Inbox',
  components: {
    Header,
  },
  data() {
    return {
      titleHeader: "Inbox",
      showToken: false,
      showBg: true,
    };
  },
  mounted() {
    // console.log(this.store, this.coin);
    this.getInbox()
  },
  computed: {
    ...mapState([
      'coin',
      'inboxList'
    ])
  },
  methods: {
    ...mapActions([
      'getInbox',
    ]),
    goTo(page) {
        this.$router.push({ name: page });
    },
    handleClick(id) {
      // console.log(id);
      this.$router.push({ path: `/inbox/${id}` })
    },
    wordDesc(word) {
      return word.split(/\s+/).slice(0,10).join(" ") + "..."
    },
    addHours(date) {
      let dateYears = new Date(date).toISOString().slice(0, 10)
      var hour = new Date(date).toLocaleTimeString();
      // console.log(dateYears + hour);
      return dateYears + ", " + hour;
    }
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.title {
  position: relative;
}
.fs-9 {
  font-size: 1rem;
}
.round-16 {
  border-radius: 16px;
}
.card-body {
  padding-top: .6rem;
  padding-bottom: .6rem;
}
.inbox-date {
  font-size: 0.7rem;
    position: absolute;
    right: 0rem;
    top: .3rem;
}

/* .inbox-content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  line-clamp: 2;
  overflow: hidden;
    font-stretch: condensed;
} */
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
</style>
