<template>
  <section class="px-3">
    <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
    <div class="pt-6">
      <h3></h3>
      <p class="text-left" style="font-size:.9rem">
        <ol class="pl-4">
          <li>Cuantastic adalah portal berisikan game-game seru dan kekinian</li>
          <li>Untuk memainkan game-game di portal Cuantastic wajib untuk registrasi atau berlangganan terlebih dahulu</li>
          <li>Biaya berlangganan game di Cuantastic mulai dari Rp1000/pesan (belum termasuk PPN 11%)</li>
          <li>Jika sukses berlangganan di Cuantastic maka Pelanggan akan mendapatkan Token main sesuai dengan tarif paket berlangganannya</li>
          <li>Jika Token main habis, maka Pelanggan bisa membeli Token mulai dari Rp1000 untuk 1 Token</li>
          <li>Untuk memainkan game di portal Cuantastic, pastikan memiliki jaringan internet yang aktif</li>
          <li>Setiap bermain game di Cuantastic akan mendapatkan Skor yang dapat diredeem</li>
          <li>Untuk meredeem/ menukar skor yang telah terkumpul mengikuti ketentuan yang berlaku di Program Pesta Reward</li>
          <li>Info detail Program Pesta Reward bisa di akses di <a href="http://pestareward.id">http://pestareward.id</a></li>
          <li>Perhitungan Skor:<br />
            <ol type="a">
              <li>Skor Game: Diperoleh dari game yang dimainkan dan merupakan akumulasi keseluruhan game didalam portal Cuantastic</li>
              <li>Skor Loyalty: Diperoleh dari sukses berlangganan = 10 skor per sukses charge, dan sukses renewal = 10 skor per sukses charge</li>
            </ol>
          </li>
          <li>Perhitungan Token dikenakan biaya mulai dari Rp1rb/1token, Rp2rb/3token, Rp3rb/5token, Rp5rb/10token</li>
          <li>Info lebih lanjut bisa menghubungi Customer Service kami di: 021-22732014</li>
          <li>Demi menjaga keamanan dan melindungi privacy Pelanggan, Cuantastic tidak menampilkan msisdn/ no handphone Pelanggan melainkan menggunakan encrypted number sebagai Player ID</li>
          <li>Tersedia Reward keren untuk Pelanggan setia di program PESTA REWARD</li>
        </ol>
      </p>
  
      <p class="text-left" style="font-size:.9rem">
        Syarat dan Ketentuan Program Pesta Reward
      </p>
      <p class="text-left" style="font-size:.9rem">
        Program PESTA REWARD disediakan untuk Seluruh Pelanggan setia Pesta Reward
      </p>
  
      <p class="text-left" style="font-size:.9rem">
        <ul>
          <li>Program PESTA REWARD diawasi dan diputuskan oleh Juri yang beranggotakan Pihak Penyelenggara</li>
          <li>Keputusan juri dan penyelenggara tidak dapat diganggu gugat</li>
          <li>Program Pesta Reward ini merupakan program yang berbentuk kompetisi untuk seluruh pelanggan setia Pesta Reward</li>
          <li>Pemenang Pesta Reward adalah Pelanggan loyal yang masih dan harus terdaftar / aktif dalam layanan Pesta Reward</li>
          <li>Dalam proses tahap pembagian dan pengiriman reward untuk dipastikan agar Pemenang tetap aktif berlangganan dilayanan Pesta Reward hingga proses serah terima selesai sepenuhnya.</li>
          <li>Jika dalam proses tahap pembagian, pengiriman dan serah terima reward Pemenang tidak aktif berlangganan dilayanan Pesta Reward, maka dinyatakan gugur/ di diskualifikasi dari layanan Pesta Reward</li>
          <li>Pelanggan yang terdaftar dan aktif dilayanan/ keyword PESTA REWARD akan mendapatkan SKOR yang dapat ditukarkan dengan Reward menarik</li>
          <li>Yang bisa atau berhak menukarkan skornya adalah Pelanggan yang memiliki Skor Tertinggi dan Tercepat disetiap periodenya, berdasarkan catatan log di system.</li>
          <li>Pelanggan hanya berhak menukarkan rewardnya 1x disetiap periodenya, dan tidak bisa mendapatkan reward yang sama secara berulang diperiode yang sama.</li>
          <li>Pelanggan hanya bisa mendapatkan reward yang berbeda di periode yang berbeda. Contoh penjelasan: Misal Pelanggan &ldquo;A&rdquo; sudah pernah menerima reward di periode BULAN ke 1, maka Ia tidak berhak lagi menerima reward di periode bulan yang sama, namun Pelanggan &ldquo;A&rdquo; berhak mendapatkan reward kembali di periode berikutnya misalnya di periode BULAN ke 2 dst.</li>
          <li>Pemenang reward hanya berlaku untuk 1 msisdn atau 1 nomor handphone dan 1 kartu identitas dengan Nama yang sama.</li>
          <li>Para Pemenang akan dihubungi langsung oleh pihak Customer Service Pesta Reward untuk verifikasi data.</li>
          <li>Penukaran skor bersifat WAJIB untuk Peserta/ Pemenang.<br />Peserta yang ikut dilayanan PESTA REWARD akan dikenakan biaya mulai dari Rp1rb/sms, 3 sms/minggu.</li>
          <li>Pelanggan yang sukses terpotong biaya, maka akan mendapatkan SKOR senilai 10.</li>
          <li>Penghitungan Skor akan kembali 0 (nol) atau posisi awal jika Skor sudah ditukarkan dengan reward tertentu.</li>
          <li>Pelanggan yang menang dan akan meredeem skornya wajib mengirimkan Data-data administrasi antara lain:<br />&ndash; Fotocopy kartu identitas diri yang masih berlaku (KTP/SIM dan KK)<br />&ndash; Nomor MSISDN/HP sesuai dengan yang telah didaftar dalam database Penyelenggara dan masih aktif mengikuti/ terdaftar dilayanan PESTA REWARD.</li>
          <li>Alamat pengiriman Reward harus sesuai dengan alamat di KTP.</li>
          <li>Data-data administrasi dapat dikirim via email ke alamat cs@pestareward.id, Jika dalam jangka waktu 1 (satu) minggu setelah tanggal pengumuman Pelanggan dengan Skor tertinggi dan tercepat tidak mengirimkan data-data kelengkapan administrasi yang diminta oleh Penyelenggara, maka reward dapat diberikan ke Pelanggan lain yang memenuhi kriteria.</li>
          <li>Periode program ini berlaku selama 1 tahun dan diperpanjang otomatis.Ini BUKAN PROGRAM UNDIAN.</li>
          <li>Total reward yang diberikan kepada pelanggan setia Pesta Reward untuk program layanan ini tidak terbatas selama periode waktu yang sudah di tentukan oleh pihak penyelenggara</li>
          <li>Tidak mengandung unsur layanan untung-untungan.</li>
          <li>Ketentuan atas layanan ini tidak bersifat final, segala perubahan terhadap syarat &amp; ketentuan adalah Hak Juri.</li>
          <li>Reward sewaktu-waktu dapat berubah tanpa ada pemberitahuan sebelumnya. Jika reward tidak tersedia, misal stok produk dipasaran habis dan atau sudah discontinue, maka Pihak Penyelenggara berhak untuk menggantikan dengan reward lainnya yang sejenis dana tau senilai dengan reward yang seharusnya diterima oleh Pemenang. Atau menggantikannya dengan Uang Tunai, namun hal ini merupakan opsi dari Pihak Penyelenggara dan BUKAN pilihan dari Pemenang atau Peserta.</li>
          <li>Ketika Pelanggan dalam kondisi tidak memiliki Pulsa atau pulsa tidak mencukupi :<br />&ndash; Pelanggan tetap dalam status berlangganan layanan.<br />&ndash; Pelanggan tidak mendapatkan konten atau layanan.<br />&ndash; Saat Pelanggan telah memiliki Pulsa kembali, maka secara otomatis Pelanggan berhak mendapatkan <br />konten atau layanannya kembali.<br />&ndash; Status Pelanggan masih akan tetap berlangganan selama Pelanggan belum melakukan pemberhentian <br />berlangganan layanan atau unreg.</li>
        </ul>
      </p>
  
      <p class="text-left" style="font-size:.9rem">
        Info lebih lanjut klik <a href="http://pestareward.id">pestareward.id</a>
      </p>
        
    </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import { mapState } from 'vuex' 
  import Header from '@/components/Header.vue'
  export default {
    name: 'Tnc',
    components: {
      Header,
    },
    data() {
      return {
        titleHeader: "Syarat & Ketentuan",
        showToken: false,
        showBg: true,
      };
    },
    // mounted() {
    //   console.log(this.store, this.coin);
    // },
    computed: {
      ...mapState({
        coin: 'coin',
      })
    },
    methods: {
      goTo(page) {
          this.$router.push({ name: page });
      },
    }
  }
  </script>
  
  <style>
  body {
      background-color: white;
  }
  </style>
  <style scoped>
  .actives {
    background-color: #fff !important;
    font-weight: 600;
  }
  .bg-burem {
    background-color: #EFF0F6 !important;
  }
  .text-burem a{
    color: #A0A3BD !important;
  }
  .text-hitam a{
    color: #212121 !important;
  }
  </style>
  